body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.input2fa {
  width: 45px;
  height: 45px;
  padding: 0;
  font-size: 24px;
  text-align: center;
  margin-right: 12px;
  text-transform: uppercase;
  color: #494949;
  font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
  sans-serif;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  background: #fff;
  background-clip: padding-box;
}

.input2fa:focus {
  appearance: none;
  outline: 0;
  box-shadow: 0 0 0 3px rgb(131 192 253 / 50%);
}

@font-face {
  font-family: "Lynx";
  src: local("Lynx"),
  url("./components/fonts/Lynx.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Raleway";
  src: local("Raleway"),
  url("components/fonts/Raleway-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: local("Raleway"),
  url("components/fonts/Raleway-Italic-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
